<template>
  <div class="p-3 pt-0">
    <div class="limit p-3">
      <h1 class="title-h1">Pacientes</h1>

      <div class="container-service mt-4" style="margin-bottom: 20px;">
        <div class="left">
          <!-- <Button class="btn principal" @click="add">Adicionar</Button> -->
          <div class="btns-frequency">
            <!-- <Button class="btn" v-for:="item in btnFilter" :key="item" :class="isFilter(item.label)" @click="changeFilter(item)"> -->
            <!-- <Button class="btn gap-8" v-for:="item in btnFilter" :key="item" :class="isFilter(item.label)">
              {{ item.label }}
              <img src="../../assets/icons/expanded-menu.png" alt="expandir menu" />
            </Button> -->
          </div>
        </div>

        <!-- //Search component -->
        <span class="container-search p-input-icon-left">
          <i class="pi pi-search" v-tooltip.left="'Buscar'" @change="onChangeSearch($event.target.value)"
            v-if="!isLoading" />
          <i class="pi pi-spin pi-spinner" v-else />
          <InputText class="search__input text-center" type="text" :disabled="isLoading" :class="isLoading && 'cursor-not-allowed'" placeholder="Pesquisar" v-model="searchInput"
            @change="onChangeSearch($event.target.value)" />
        </span>
      </div>

      <!-- Buttons de notificar e bloqueados -->
      <div class="content">
        <div class="flex align-items-center justify-content-end txt-btn gap-16">
          <div class="flex align-items-center gap-4 cursor-pointer" @click="openDialogNotify">
            <img src="../../assets/icons/patients/notification.png" alt="" />
            <span>Notificar</span>
          </div>
          <div class="flex align-items-center gap-4 cursor-pointer">
            <img src="../../assets/icons/patients/block.png" alt="" />
            <span>Bloqueados</span>
          </div>
        </div>
        
        <!-- Listagem -->
        <List v-if="!isLoading && dataPatients.length !==0" :obj="dataPatients"/>
        <NoRegister v-if="!isLoading && dataPatients.length === 0" />
        <Loader v-if="isLoading"/>
      </div>

    </div>

    <!-- Modais -->
    <DialogNotify v-model:visible="viewNotify" />

  </div>
</template>

<script>
import { ref, onMounted, watch, computed } from "vue";
import actions from "./actions";
import DialogNotify from "./Dialogs/DialogNotify/DialogNotify.vue";
import ListPatients from "./actions.js"
import List from "./List/list.vue";
import {
  SearchListPatientsRequest
} from "../core/grpc/generated/appointment_grpc_web_pb";
import appointmentService from "../core/services/appointmentService";
import Loader from "./Loader";
import NoRegister from "./List/noRegister.vue"

export default {
  components: {
    List,
    DialogNotify,
    Loader,
    NoRegister,
  },
  setup() {
    const viewAdd = ref(false);
    const viewNotify = ref(false);
    const isLoading = ref(false);
    const searchInput = ref("");
    const dataPatients = ref([]);
    const filter = ref("");
    const btnFilter = [
      { label: "Status" },
      { label: "Locais" },
      { label: "Profissão" },
      { label: "Especialidade" },
    ];

    const add = () => {
      viewAdd.value = true;
    };

    const ListPatients = async () => {
      isLoading.value = true;
      try {
        const res = await actions.ListPatients();
        const { success, patientsList } = res;

        if (success && patientsList && patientsList.length) {
          dataPatients.value = patientsList;
        }
      } catch (error) {
        console.log(error);
      }
      isLoading.value = false;
    };

    const SearchListPatients = async word => {
      isLoading.value = true
      if (word.length > 0) {
          try {
            const req = new SearchListPatientsRequest();
            req.setWord(word);

            const res = await appointmentService.searchListPatients(req);
            const { success } = res;

              if (success) {
                console.log(res);
                dataPatients.value = res.patientsList;
              }

          } catch (error) {
              console.log(error)
          }
      } else {
        ListPatients();
      }
      isLoading.value = false
    };

    const onChangeSearch = word => {
      SearchListPatients(word)
    };

    const isFilter = (item) => {
      if (item !== filter.value) {
        return "inative";
      }
    };

    const changeFilter = (item) => {
      filter.value = item.label;
    };

    const openDialogNotify = () => {
      viewNotify.value = true;
    }

    onMounted(async () => {
      isLoading.value = true;
      const res = await actions.ListPatients();
      console.log("ListPatients", res)
      if(res?.success && res?. patientsList?.length){
        dataPatients.value = res.patientsList
      }

      isLoading.value = false;
    });

    return {
      viewAdd,
      viewNotify,
      add,
      filter,
      btnFilter,
      isFilter,
      changeFilter,
      isLoading,
      searchInput,
      openDialogNotify,
      ListPatients,
      dataPatients,
      SearchListPatients,
      onChangeSearch,
    }
  }
}
</script>


<style lang="scss" scoped>
.container-service {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 36px !important;

  .principal {
    display: flex;
    justify-content: center;
    width: 176px;
    background: linear-gradient(171.55deg, #FF6A33 60.76%, #FF4500 99.68%);
  }

  input {
    width: 200px;
  }

  .search__input {
    box-shadow: 0px 8px 24px rgba(61, 71, 102, 0.08), 0px 3px 6px rgba(61, 71, 102, 0.06);
  }

  .left {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 278px);
    gap: 10px;

    @media (max-width: 970px) {
      width: 100%;
      margin-bottom: 10px;
    }

    @media (max-width: 500px) {
      flex-direction: column;
      margin-bottom: 10px;
    }
  }
}

.btn {
  width: max-content;
  height: 40px;
}
.txt-btn{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 31px;
  color: #828282;
}

.btns-frequency {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;

  .inative {
    background: #fff !important;
    border: 1px solid #fff;
    color: #828282 !important;
  }

  .btn:first-child {
    background: #FF6A33;
    padding: 0px 32px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .btn:not(:first-child):not(:last-child) {
    background: #FF6A33;
    padding: 0px 32px;
    border-radius: 0px;
  }

  .btn:last-child {
    background: #FF6A33;
    padding: 0px 32px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  @media (max-width: 995px) {
    justify-content: flex-start;
  }

  @media (max-width: 456px) {
    justify-content: flex-start;
    margin-bottom: 10px;
  }
}

:deep() {
  input {
    height: 40px;
    border-radius: 8px;
    padding: 0 19px;
    color: #bdbdbd;
    border: 1px solid white;
    outline: none;

    &::-webkit-input-placeholder {
      color: #bdbdbd;
    }

    &:focus {
      border-color: #ff6a33;
    }
  }

  .container-search {
    width: 260px;

    input {
      width: 100% !important;
    }
  }
  .pi, .pi-search {
    color: #BDBDBD !important;
  }

  .p-input-icon-right {
    cursor: pointer;
    vertical-align: middle;
  }
}
</style>