import appointmentService from "../core/services/appointmentService"
import { EmptyRequest } from "../core/grpc/generated/appointment_pb.js"

const ListPatients = async () => {
    try {
        const req = new EmptyRequest();
        const res = await appointmentService.listPatients(req);

        return res;
        
    } catch (error) {
        console.log(error);
        return error;
    }
}

export default {
    ListPatients
}